<template>
  <div class="storage-time">
    <KTCodePreview v-bind:title="'Thời gian lưu kho'">
      <template v-slot:toolbar>
        <b-dropdown size="sm" id="dropdown-1" right>
          <template slot="button-content">
            <i style="font-size: 1rem" class="fas fa-cog"></i>
            <span class="font-weight-bolder">Thao tác</span>
          </template>
          <b-dropdown-item @click="reportClick">
            <span>
              <i style="font-size: 1rem" class="far fa-file-excel"></i>
              &nbsp; Xuất Excel</span
            >
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template v-slot:preview>
        <b-row class="ml-1">
          <div class="col-md-12">
            <div class="row">
              <b-input
                size="sm"
                placeholder="Nhập tên, mã, mã vạch sản phẩm"
                v-model="searchProduct"
                class="col-md-3 mb-4"
                v-on:keyup.enter="onFilter()"
              ></b-input>
              <div class="col-md-3">
                <Autosuggest
                  :model="selectedCateName"
                  :suggestions="filteredCateOptions"
                  placeholder="danh mục sản phẩm"
                  @select="onSelectedCate"
                  @change="onInputCateChange"
                  suggestionName="suggestionName"
                />
              </div>
              <b-col md="3">
                <b-row>
                  <b-col class="col-md-4">
                    <span>Ngày bắt đầu:</span>
                  </b-col>
                  <b-col class="col-md-3 p-0">
                    <b-form-select
                      class="select-style"
                      v-model="selectOperatorFirstDate"
                      :options="listOperator"
                      size="sm"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-col>
                  <b-col class="col-md-5 p-0">
                    <b-input
                      size="sm"
                      placeholder="Số ngày"
                      v-model="numDayFromFirstDate"
                      @keypress = "onlyNumber($event)"
                      :number = "true"
                      class="text-right"
                    ></b-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="3">
                <b-row>
                  <b-col class="col-md-4">
                    <span>Ngày kết thúc:</span>
                  </b-col>
                  <b-col class="col-md-3 p-0">
                    <b-form-select
                      class="select-style"
                      v-model="selectOperatorLastDate"
                      :options="listOperator"
                      size="sm"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-col>
                  <b-col class="col-md-5 p-0">
                    <b-input
                      size="sm"
                      placeholder="Số ngày"
                      v-model="numDayFromLastDate"
                      @keypress = "onlyNumber($event)"
                      :number = "true"
                      class="text-right"
                    ></b-input>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-row>
        <b-row class="ml-1">
          <div class="col-md-3 pl-0">
            <div class="row">
              <label class="col-md-4 d-flex align-items-center"
                >Thể loại:</label
              >
              <b-form-select
                size="sm"
                v-model="selectedProductType"
                :options="listProductType"
                value-field="id"
                text-field="name"
                class="col-md-8 select-style"
              ></b-form-select>
            </div>
          </div>
          <div class="col-md-3">
            <Autosuggest
              :model="selectedCate2Name"
              :suggestions="filteredCate2Options"
              placeholder="danh mục nội bộ"
              @select="onSelectedCate2"
              @change="onInputCate2Change"
              suggestionName="suggestionName"
            />
          </div>
          <b-col md="3" class="pr-0">
            <div>
              <BDropdownCustom
                v-model="value"
                :options="filterdOptions"
                :placeholder="'cửa hàng'"
                :matchKey="['name', 'shortName']"
                :textProp="'name'"
                :searchable="true"
                :limit="5"
              />
            </div>
          </b-col>
          <b-col md="3">
            <b-row>
              <b-col class="col-md-4">
                <span>Ngày bán cuối:</span>
              </b-col>
              <b-col class="col-md-3 p-0">
                <b-form-select
                  class="select-style"
                  v-model="selectOperatorLastSaleDate"
                  :options="listOperator"
                  size="sm"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
              <b-col class="col-md-5 p-0">
                <b-input
                  size="sm"
                  placeholder="Số ngày"
                  v-model="numDayFromLastSaleDate"
                  @keypress = "onlyNumber($event)"
                  :number = "true"
                  class="text-right"
                ></b-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="ml-1 my-2">
          <b-col md="3" class="pl-0">
            <div class="row">
              <label class="col-md-4 d-flex align-items-center"
                >Trạng thái bán:</label
              >
              <b-form-select
                size="sm"
                v-model="selectedSaleStatus"
                :options="listSaleStatus"
                value-field="id"
                text-field="name"
                class="col-md-8 select-style"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row class="pl-0">
          <b-col md="10" class="pl-0">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
              class="col-md-8 mb-4 ml-4"
              >Lọc</b-button
            >
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ convertPrice (totalItem) }}
              </p>
            </div>
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="listItem"
            :fields="fields"
            sort-by="count"
            class="table-bordered table-hover col-md-12"
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="8"></vcl-table>
            </template>

            <template v-slot:cell(count)="row">
              <div style="text-align: center">
                <span v-text="row.item.count" class="text-right"></span>
              </div>
            </template>
            <template v-slot:cell(productName)="row">
              <span v-text="row.item.productCode"></span>
              <br />
              <span
                v-text="row.item.productName"
                style="white-space: normal; color: #3699ff"
              ></span>
            </template>
            <template v-slot:cell(totalQuantityInStock)="row">
              <span v-text="convertPrice (row.item.totalQuantityInStock)"></span>
            </template>
            <template v-slot:cell(originalPrice)="row">
              <span v-text="convertPrice (row.item.originalPrice)"></span>
            </template>
            <template v-slot:cell(sellingPrice)="row">
              <span
                v-text="convertPrice (row.item.sellingPrice)"
                style="color: #00bcd4"
              ></span>
            </template>
            <template v-slot:cell(numDayFromFirstDate)="row">
              <span
                v-if="row.item.numDayFromFirstDate > 0"
                v-text="convertPrice (row.item.numDayFromFirstDate)"
              ></span>
            </template>
            <template v-slot:cell(numDayFromLastDate)="row">
              <span
                v-if="row.item.numDayFromLastDate > 0"
                v-text="convertPrice (row.item.numDayFromLastDate)"
              ></span>
            </template>
            <template v-slot:cell(numDayFromLastSaleDate)="row">
              <span
                v-if="row.item.numDayFromLastSaleDate > 0"
                v-text="convertPrice (row.item.numDayFromLastSaleDate)"
              ></span>
              <span
                v-else-if="
                  row.item.numDayFromLastSaleDate === 0 &&
                  !row.item.lastSaleDate
                "
                v-text="'Chưa bán'"
                class="text-warning"
              ></span>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ convertPrice (totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
.storage-time {
  .d-flex {
    display: -webkit-box !important;
  }
  .text-title {
    font-weight: 600;
    font-size: 16px;
  }
  .text-lable-total {
    font-weight: 600;
  }
  .text-report {
    font-weight: 500;
    font-size: 13px;
  }
  .labelInput {
    font-weight: 600;
  }
  .productCode:hover {
    text-decoration: underline;
  }

  .inputText {
    width: 22%;
  }
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .dropdown-header {
    color: #3699ff !important;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .imeiCode:hover {
    text-decoration: underline;
  }
  .table-wrapper {
    overflow-x: scroll;
    overflow-y: visible;
  }
  .test {
    position: absolute;
    right: 1vw;
  }
  .checkbox-label {
    display: block;
  }
  .table-wrap {
    width: 100%;
    overflow-x: auto;
  }
   .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { removeAccents } from '@/utils/common';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import { cloneDeep, map, find, assign } from 'lodash';
import { convertPrice } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '20%',
          },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tổng tồn',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'firstDate',
          label: 'Ngày bắt đầu XNK',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '9%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'lastDate',
          label: 'Ngày cuối cùng XNK',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '9%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'lastSaleDate',
          label: 'Ngày bán cuối cùng',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '9%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'numDayFromFirstDate',
          label: 'Lưu từ ngày bắt đầu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '9%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'numDayFromLastDate',
          label: 'Lưu từ ngày cuối cùng',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '9%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'numDayFromLastSaleDate',
          label: 'Lưu từ bán cuối cùng',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '9%',
          },
          tdClass: 'text-right text-report',
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 1,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listProductType: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      selectedProductType: null,
      value: [],
      options: [],
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      fromNo: 0,
      toNo: 0,
      LIMIT_PAGE: 30,
      selectOperatorFirstDate: 5,
      listOperator: [
        {
          id: 5,
          name: '>=',
        },
        {
          id: 4,
          name: '<=',
        },
      ],
      numDayFromFirstDate: 0,
      selectOperatorLastDate: 5,
      numDayFromLastDate: 0,
      selectOperatorLastSaleDate: 5,
      numDayFromLastSaleDate: 0,
      listItem: [],
      listSaleStatus: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Đã bán' },
        { id: 2, name: 'Chưa bán' },
      ],
      selectedSaleStatus: null,
      filterdOptions: []
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    BDropdownCustom,
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thời gian lưu kho', route: '/storage-time' },
      { title: 'Thời gian lưu kho' },
    ]);
    this.fetchStoreForOption();
    this.fetchStoreByUser();
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    this.fetchCategory();
    this.fetchInternalCategory();
    this.checkViewOriginalPrice();
  },
  methods: {
    convertPrice,
    onlyNumber(evt){
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
      } else {
      return true;
      }
    },
    fetchData: function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }
      if (listId.length === 0) {
        this.options.forEach((element) => {
          listId.push(element.code);
        });
      }
      this.filterdOptions = cloneDeep(this.options);
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }

      const param = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        searchProduct: this.searchProduct,
        searchStore: listId,
        productType: this.selectedProductType,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
        numDayFromFirstDate: this.numDayFromFirstDate,
        operatorFromFirstDate: this.selectOperatorFirstDate,
        numDayFromLastDate: this.numDayFromLastDate,
        operatorFromLastDate: this.selectOperatorLastDate,
        numDayFromLastSaleDate: this.numDayFromLastSaleDate,
        operatorFromLastSaleDate: this.selectOperatorLastSaleDate,
        saleSatus: this.selectedSaleStatus,
      };

      const paramQuery = {
        params: param,
      };

      this.onLoading = true;
      this.listItem = [];
      ApiService.setHeader();
      ApiService.query('stock-reports/storage-time', paramQuery).then(
        ({ data }) => {
          this.listItem = data.data.data;
          this.numberOfPage = data.data.total_page;
          this.totalItem = data.data.total_row;
          this.onLoading = false;
          this.fromNo = (this.page - 1) * this.LIMIT_PAGE + 1;
          this.toNo = this.LIMIT_PAGE * this.page;
          if (this.toNo > this.totalItem) {
            this.toNo = this.totalItem;
          }
        }
      );
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    reportClick: async function () {
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      };
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (listId.length === 0) {
        this.options.forEach((element) => {
          listId.push(element.code);
        });
      }
      this.filterdOptions = cloneDeep(this.options);
      const params = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        searchProduct: this.searchProduct,
        searchStore: listId,
        productType: this.selectedProductType,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
        numDayFromFirstDate: this.numDayFromFirstDate,
        operatorFromFirstDate: this.selectOperatorFirstDate,
        numDayFromLastDate: this.numDayFromLastDate,
        operatorFromLastDate: this.selectOperatorLastDate,
        numDayFromLastSaleDate: this.numDayFromLastSaleDate,
        operatorFromLastSaleDate: this.selectOperatorLastSaleDate,
        saleSatus: this.selectedSaleStatus,
      };

      const url = `${BASE_URL}stock-reports/export-excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice'
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
      }
    },
    fetchStoreForOption: async function () {
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
          this.options.push(option);
        });
        this.filterdOptions = cloneDeep(this.options);
      });
    },

    onSelect(option) {
      const index = this.options.findIndex((item) => item.name == option.name);
      if (index !== -1) this.options[index].checked = true;
      this.filterdOptions = cloneDeep(this.options);
    },

    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
      this.filterdOptions = cloneDeep(this.options);
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-storage-time',
      });
      this.fetchData();
    },
    fetchCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions[0].data];
      });
    },
    fetchInternalCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.cate2Options[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.cate2Options[0].data.push(item);
          });
          this.filteredCate2Options = [...this.cate2Options[0].data];
        }
      );
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })

      this.filteredCateOptions = [...filteredData];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.cate2Options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })

      this.filteredCate2Options = [...filteredData];
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.options = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.options.push(option);
            if (!data.data.viewAll) {
              let index = this.options.findIndex(
                (item) => item.code == element.id
              );
              this.options[index].checked = true;
              this.value.push(this.options[index]);
            }
          });
          this.filterdOptions = cloneDeep(this.options);
        }
        this.fetchData();
      });
    },
    onInputed(textInput = '') {
        this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.options);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptions = map(options, obj => {
          return assign(obj, find(this.filterdOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filterdOptions, { id: obj.id }));
      });

      this.filterdOptions = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter(item => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
};
</script>
